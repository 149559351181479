import React, {useState, useEffect} from 'react';
import {Box, TextField, Button, LinearProgress, InputAdornment, IconButton, Alert} from '@mui/material';
import {useLocation} from 'wouter';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';

export default function Login() {
  const [,setLocation] = useLocation();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({type: '', message: ''});
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const urlHash = window.location.hash.substring(1);

    if (urlHash) {
      switch (urlHash) {
        case 'logout':
          setAlert({type: 'info', message: 'Se ha cerrado la sesión.'});
          break;
        
        case 'expired':
          setAlert({type: 'warning', message: 'La sesión ha expirado.'});
          break;

        default:
          setAlert({type: '', message: ''});
          break;
      }
    }
  }, []);
  
  async function handleSubmit(e) {
    e.preventDefault();
    setLoading(true);
    setAlert({type:'', message:''});
    
    async function hashData(string) {
      const encoder = new TextEncoder();
      const data = encoder.encode(string);
      const hash = await crypto.subtle.digest('SHA-256', data);
      return hex(hash);
    }
    
    function hex(buffer) {
      const hashArray = Array.from(new Uint8Array(buffer));
      const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
      return hashHex;
    }
    
    const hashedUser = await hashData(username);
    const hashedPass = await hashData(password);
    
    try{
      const response = await fetch(apiUrl + '/login.php', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username: hashedUser, password: hashedPass })
      });
      
      if (!response.ok) {
        setLoading(false);
        throw new Error(`HTTP Error! status: ${response.status}`);
      }
      
      const data = await response.json();
      if (data.status === 'success') {
        const expirationTime = new Date().getTime() + 30 * 60 * 1000; //30 Minutes
        sessionStorage.setItem('authToken', data.token);
        sessionStorage.setItem('tokenExpiration', expirationTime.toString());
        setLocation('/display');
      } else {
        setAlert({type:'error', message:data.message});
        setLoading(false);
      }
    } catch (error) {
      console.error('Fetch Error:', error);
      setAlert({type:'error', message:error});
      setLoading(false);
    }
  };

  function handleShowPassword() {
    setShowPassword(!showPassword);
  }

  return (
    <Box component="form" onSubmit={handleSubmit} sx={{maxWidth:'40vw', position:'absolute', top:'50%', left:'50%', transform:'translate(-50%,-50%)'}}>
      <TextField 
        required 
        type="text" 
        label="Username"
        autoComplete='username'
        fullWidth
        autoFocus
        value={username} 
        onChange={(e) => setUsername(e.target.value)} 
        sx={{pb:3}}
      />
      <TextField 
        required 
        type={showPassword ? 'text' : 'password'}
        label="Password" 
        autoComplete='current-password'
        fullWidth
        value={password} 
        onChange={(e) => setPassword(e.target.value)} 
        sx={{pb:3}}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={handleShowPassword} edge="end">
                {showPassword ? <VisibilityOffOutlinedIcon/> : <VisibilityOutlinedIcon/>}
              </IconButton>
            </InputAdornment>
          )
        }}
      />
      <Button type="submit" variant="outlined" fullWidth sx={{minHeight:50, borderColor:'darkgrey', color:'black', '&:hover':{borderColor:'black', backgroundColor:'whitesmoke'}}}>
        {!loading ? 'Login' : <LinearProgress sx={{width:1,height:3}}/> }
      </Button>
      {alert.message && (
        <Alert severity={alert.type} sx={{mb:2, mt:3, border:'1px solid darkgrey', position:'fixed', left:'50%', transform:'translate(-50%)', whiteSpace:'nowrap'}}>
          {alert.message}
        </Alert>
      )}
    </Box>
  );
}
