import {Route, Switch} from 'wouter';

import Login from 'components/login/login';
import Display from 'components/display/display.js';

export default function App() {
  return (
    <Switch>
      <Route path="/login" component={Login} />
      <Route path="/display" component={Display} />
      <Route path="" component={Display} />
    </Switch>
  );
}
