import React, {useState} from 'react';
import {useLocation} from 'wouter';
import {AppBar, Toolbar as MuiToolbar, Box, Tooltip, IconButton, TextField, Autocomplete} from '@mui/material';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import useApiProtected from 'hooks/apiCall/apiCall';

export default function Toolbar({onSearch}){
  const {callApiProtected} = useApiProtected();
  const [,setLocation] = useLocation();
  const [data, setData] = useState({});
  const [options, setOptions] = useState([]);

  function logout() {
    sessionStorage.removeItem('authToken');
    sessionStorage.removeItem('tokenExpiration');
    setLocation('/login#logout');
  }

  // Receive JSONs
  async function fetchData() {
    if (Object.keys(data).length === 0) {
      console.log('exec');
      try {    
        const apiData = await callApiProtected('/json.php');
        setData(apiData);

      } catch (err) {
        console.error('Error: Data not available');
      }
    }
  }

  // Filter
  function handleInputChange(event,value) {
    if (value.length > 3) {
      const filteredResults = Object.values(data).filter(item =>
        item.vhosts.some(vhost =>
          vhost.serverName.toLowerCase().includes(value.toLowerCase()) ||
          vhost.serverAlias.toLowerCase().includes(value.toLowerCase())
        )
      ).map(item => item.hostname);
      
      setOptions(filteredResults);
    } else {
      setOptions([]);
    }
  };

  function handleOptionSelect(event, value) {
    if (value) {
      onSearch(value);
      setData({});
    }
  }

  return(
    <AppBar position="absolute" sx={{zIndex:1, bgcolor:"#F8F9FA"}}>
      <MuiToolbar>

        {/* SearchBox */}
        <Box sx={{flexGrow:1}}/>
        <Autocomplete
          freeSolo
          filterOptions={(x) => x}
          options={options}
          onInputChange={handleInputChange}
          onChange={handleOptionSelect}
          renderInput={(params) => (
            <TextField 
              {...params}
              label="Buscar por URL"
              variant="outlined"
              size="small"
              onClick={fetchData}
              sx={{width:'30vw'}}
            />
          )}
        />

        {/* LOGOUT */}
        <Box sx={{flexGrow:1}}/>
        <Tooltip title="Cerrar Sesión">
          <IconButton onClick={logout}>
            <ExitToAppIcon/>
          </IconButton>
        </Tooltip>

      </MuiToolbar>
    </AppBar>
  );
}
