import React from 'react';
import {SvgIcon, Paper, Typography} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import './loading.css';

export default function Loading() {
  return (
    <Paper className='loader-container'>
      <SvgIcon component={SearchIcon} className='loader' />
      <Typography className='loader-text'>Loading...</Typography>
    </Paper>
  );
}
