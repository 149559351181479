import React, {useState, useEffect} from 'react';
import {useLocation} from 'wouter';
import {sessionExpired} from 'hooks/apiCall/apiCall'
import {Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography} from '@mui/material';
import AlarmAddIcon from '@mui/icons-material/AlarmAdd';


export function useTokenExpiration() {
  const [,setLocation] = useLocation();
  const [showWarning, setShowWarning] = useState(false);
  const [remainingTime, setRemainingTime] = useState(1);
  const [minutesSeconds, setMinutesSeconds] = useState({minutes:0, seconds:0});
  const apiUrl = process.env.REACT_APP_API_URL;
  

  useEffect(() => {
    const token = sessionStorage.getItem('authToken');
    const tokenExpiration = sessionStorage.getItem('tokenExpiration');

    if (token && tokenExpiration) {
      const expirationTime = parseInt(tokenExpiration, 10);
      const updateRemainingTime = () => {
        const currentTime = new Date().getTime();
        setRemainingTime(expirationTime - currentTime);

        const remainingMinutes = Math.floor(remainingTime / 60000);
        const remainingSeconds = Math.floor((remainingTime % 60000) / 1000);
        setMinutesSeconds({minutes:remainingMinutes, seconds:remainingSeconds});

        if (remainingMinutes === 10 && remainingSeconds === 0) {
          setShowWarning(true);
        }

        if (remainingTime <= 0) {
          sessionExpired(setLocation);
        }
      };

      const intervalId = setInterval(updateRemainingTime, 1000);
      return () => clearInterval(intervalId);
    }
  }, [setLocation, remainingTime]);


  async function handleRenew() {
    const token = sessionStorage.getItem('authToken');
  
    const response = await fetch(apiUrl + '/login.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    });

    if (!response.ok) {
      throw new Error(`HTTP Error! status: ${response.status}`);
    }

    const data = await response.json();
    if (data.status === 'success') {
      const newExpirationTime = new Date().getTime() + 30 * 60 * 1000; //30 Minutes
      sessionStorage.setItem('authToken', data.token);
      sessionStorage.setItem('tokenExpiration', newExpirationTime.toString());

      const currentTime = new Date().getTime();
      setRemainingTime(newExpirationTime - currentTime);
      setShowWarning(false);
    } else {
      sessionExpired(setLocation);
    }
  }


  function handleCloseDialog() {
    setShowWarning(false);
  }

  return {
    showWarning,
    handleRenew,
    handleCloseDialog,
    minutesSeconds
  };
}

export default function TokenExpirationDialog({showWarning, handleCloseDialog, handleRenew, minutesSeconds}) {
  return (
    <Dialog open={showWarning}>
      <DialogTitle>Advertencia de Sesión</DialogTitle>
      <DialogContent>
        <Typography variant="body1">
          Tu sesión expirará en aproximadamente: {minutesSeconds.minutes}:{minutesSeconds.seconds < 10 ? `0${minutesSeconds.seconds}` : minutesSeconds.seconds}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseDialog} variant="outlined" size="small">Entendido.</Button>
        <Button onClick={handleRenew} variant="contained" endIcon={<AlarmAddIcon/>}>Renovar Sesión</Button>
      </DialogActions>
    </Dialog>
  );
}
