//import react from "react";
import {useLocation} from 'wouter';

export default function useApiProtected() {
  const [,setLocation] = useLocation();
  const apiUrl = process.env.REACT_APP_API_URL;

  async function callApiProtected(URI) {
    const token = sessionStorage.getItem('authToken');
    const response = await fetch(apiUrl + URI, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    });
    if (response.ok) {
      const apiData = await response.json();
      return apiData.body;
    } else if (response.status === 401) {
      sessionExpired(setLocation);
    } else {
      console.error('Error fetching JSON files:', response.statusText);
    }
  }

  return {callApiProtected};
}

export function sessionExpired(setLocation) {
  sessionStorage.removeItem('authToken');
  sessionStorage.removeItem('tokenExpiration');
  setLocation('/login#expired');
}
