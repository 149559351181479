import React, {useState, useEffect, useRef} from 'react';
import BubbleUI from 'react-bubble-ui';
import 'react-bubble-ui/dist/index.css';
import useApiProtected from "hooks/apiCall/apiCall";
import ReactScrollWheelHandler from 'react-scroll-wheel-handler';
import './display.css';
import CheckAuth from 'hooks/checkAuth/checkAuth'
import {Paper,Grow,SvgIcon,Box,Typography} from '@mui/material';
import CloudTwoToneIcon from '@mui/icons-material/CloudTwoTone';
import LoadGrid from './jsonGrid';
import Loading from 'components/loading/loading';
import Toolbar from 'components/toolbar/toolbar';
import TokenExpirationDialog, {useTokenExpiration} from 'hooks/checkAuth/sessionToken';


export default function Display() {
  CheckAuth();
  const {showWarning, handleRenew, handleCloseDialog, minutesSeconds} = useTokenExpiration();
  
  const dialog = useRef();
  const [bubble, setBubble] = useState("");
  const [bubbleColor, setBubbleColor] = useState('rgba(0,0,0,0)');
  const [children, setChildren] = useState([]);
  const [selected, setSelected] = useState(false);
  const {callApiProtected} = useApiProtected();
  const [loading, setLoading] = useState(false);

  const devSize = {phone:500, tablet:1300};
  let avWth = window.screen.availWidth;
  let options = {
    size: avWth <= devSize.phone ? 100 :
          avWth <= devSize.tablet ? 200 : 250,
    minSize: avWth <= devSize.phone ? 30 :
             avWth <= devSize.tablet ? 100 : 100,
    gutter: avWth <= devSize.phone ? 30 :
            avWth <= devSize.tablet ? 30 : 30,
    provideProps: true,
    numCols: avWth <= devSize.phone ? 5 :
             avWth <= devSize.tablet ? 7 : 8,
    fringeWidth: 60,
    yRadius: 130,
    xRadius: 220,
    cornerRadius: 50,
    showGuides: false,
    compact: true,
    gravitation: 5
  };

  // Bubble Data
  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      try {
        const apiData = await callApiProtected('/json.php?listonly');
        const children = apiData?.map((data, i) => {
          const name = data.replace('.json', '');
          return (
            <React.Fragment key={i}>
              <Box>
                <SvgIcon component={CloudTwoToneIcon} key={i} className="childComponent" onClick={() => handleClick(name)} sx={{'& path:nth-of-type(1)': {fill: bubbleColor} }} />
                <Typography className="childText">{name}</Typography>
              </Box>
            </React.Fragment>
          );
        });
        setChildren(children);
        setLoading(false);
        
      } catch (err) {
        console.error('Error: Data not available');
      }
    }
    fetchData();
  },[]);

  // Mouse Handler
  useEffect(() => {
    if (!loading) {
      const bubbles = document.querySelector("._2MD0k");
      if (!bubbles) return;

      const img = document.querySelectorAll(".childComponent");
      img.forEach(
        (i) =>
          (i.ondragstart = () => {
            return false;
          })
      );
      const dragspeed = 2;
      let isDown = false;
      let startX;
      let startY;
      let scrollLeft;
      let scrollTop;

      bubbles.addEventListener("mousedown", (e) => {
        isDown = true;
        bubbles.classList.add("active");
        startX = e.pageX - bubbles.offsetLeft;
        startY = e.pageY - bubbles.offsetTop;
        scrollLeft = bubbles.scrollLeft;
        scrollTop = bubbles.scrollTop;
      });
      bubbles.addEventListener("mouseleave", () => {
        isDown = false;
        bubbles.classList.remove("active");
      });
      bubbles.addEventListener("mouseup", () => {
        isDown = false;
        bubbles.classList.remove("active");
      });
      bubbles.addEventListener("mousemove", (e) => {
        if (!isDown) return;
        e.preventDefault();
        const x = e.pageX - bubbles.offsetLeft;
        const y = e.pageY - bubbles.offsetTop;
        const walk = (x - startX) * dragspeed;
        const topwalk = (y - startY) * dragspeed;
        bubbles.scrollLeft = scrollLeft - walk;
        bubbles.scrollTop = scrollTop - topwalk;
      });

      return () => {
        bubbles.removeEventListener("mousedown", () => {});
        bubbles.removeEventListener("mouseleave", () => {});
        bubbles.removeEventListener("mouseup", () => {});
        bubbles.removeEventListener("mousemove", () => {});
      };
    }
  }, [loading]);

  function handleClick(bub) {
    setBubble(bub);
    setSelected(true);
  };

  // Zoom
  let level = 100;
  const zoomDisplay = (mode) => {
    mode === true ? level += 10 : level === 50 ? level = 50 : level -= 10;
    document.getElementsByClassName('_2MD0k')[0].style.zoom = level + '%'; 
  }

  // Handle Dialog
  function handleDialog(e) {
    if (loading) return;
    if (dialog.current.contains(e.target)) {
      const isCloseGrid = e.target.dataset.id === "closeGrid" || 
                          e.target.parentElement?.dataset.id === "closeGrid";

      if (!isCloseGrid) {
        return;
      }
    }

    setSelected(false);
  }
  useEffect(() => {
    document.addEventListener('mousedown', handleDialog);

    return () => {
      document.removeEventListener('mousedown', handleDialog);
    };
  }, []);

  if (loading) return <Loading />;

  return (
    <>
      <Toolbar onSearch={handleClick}/>
      <TokenExpirationDialog showWarning={showWarning} handleCloseDialog={handleCloseDialog} handleRenew={handleRenew} minutesSeconds={minutesSeconds} />
      <ReactScrollWheelHandler
        wheelConfig={[9999,1,0,0]}
        preventScroll={true}
        disableSwipe={true}
        disableSwipeWithMouse={true}
        disableKeyboard={true}
        timeout={0}
        upHandler={() => zoomDisplay(true)}
        downHandler={() => zoomDisplay(false)}
      >
        <BubbleUI options={options} className="myBubbleUI">
          {children}
        </BubbleUI>

      </ReactScrollWheelHandler>
      
      <Grow in={selected} >
        <Paper
          ref={dialog}
          elevation={15}
          className={`dialog ${selected ? 'dialogOpen' : 'dialogClose'}`}
        >
          <div className={selected ? 'visible' : 'hidden'}>
            {bubble && <LoadGrid jsonName={`${bubble}.json`} onClose={handleDialog} /> }
          </div>
        </Paper>
      </Grow>
    </>
  );
}
