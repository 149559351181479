import {useState, useEffect, useRef} from 'react';
import useApiProtected from 'hooks/apiCall/apiCall';
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, List, ListItem, ListItemIcon, ListItemText, Paper, Button, Collapse, TextField, IconButton, Tooltip} from '@mui/material';
import SmartToyOutlinedIcon from '@mui/icons-material/SmartToyOutlined';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import PublicOutlinedIcon from '@mui/icons-material/PublicOutlined';
import MonitorHeartOutlinedIcon from '@mui/icons-material/MonitorHeartOutlined';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import MemoryIcon from '@mui/icons-material/Memory';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import Loading from 'components/loading/loading';
import excelExport from 'hooks/xlsx/xlsx';
import './jsonGrid.css';

export default function LoadGrid({jsonName, onClose}) {
  const {callApiProtected} = useApiProtected();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [collapse, setCollapse] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const tableRef = useRef(null);

  //Json Data
   useEffect(() => {
    async function fetchData() {
      setLoading(true);
      try {    
        const apiData = await callApiProtected('/json.php?name=' + jsonName);
        setData(apiData);
        setLoading(false);

      } catch (err) {
        console.error('Error: Data not available');
        setLoading(false);
      }
    }

    fetchData();
  },[jsonName]); // eslint-disable-line

  //Integrated Search
  useEffect(() => {
    if (searchTerm) {
      const rows = tableRef.current.querySelectorAll('tr');

      rows.forEach(row => {
        if (row.textContent.toLowerCase().includes(searchTerm.toLowerCase())) {
          row.style.display = '';
        } else {
         row.style.display = 'none';
        }
      });
    } else {
      if (tableRef.current) {
        const rows = tableRef.current.querySelectorAll('tr');
        rows.forEach(row => row.style.display = '');
      }
    }
  }, [searchTerm]);

  if (loading) return <Loading />;
  
  // Table with CPU and general info
  const sysCol1 = (hostname,os,ip,status,cpu) => (
    <div className="table-column">
      <List>
        <ListItem>
          <ListItemIcon>
            <SmartToyOutlinedIcon />
          </ListItemIcon>
          <ListItemText primary="Hostname" secondary={hostname} />
        </ListItem>

        <ListItem>
          <ListItemIcon>
            <SaveOutlinedIcon />
          </ListItemIcon>
          <ListItemText primary="OS" secondary={os} />
        </ListItem>

        <ListItem>
          <ListItemIcon>
            <PublicOutlinedIcon />
          </ListItemIcon>
          <ListItemText primary="IP" secondary={ip} />
        </ListItem>

        <ListItem>
          <ListItemIcon>
            <MonitorHeartOutlinedIcon />
          </ListItemIcon>
          <ListItemText primary="Status" secondary={ status === 'up' ? <CheckCircleOutlinedIcon sx={{color:'green'}}/> : <CancelOutlinedIcon sx={{color:'red'}}/> } />
        </ListItem>
      </List>

      <List>
        <ListItem sx={{py:0}}>
          <ListItemIcon>
            <MemoryIcon />
          </ListItemIcon>
          <ListItemText primary="CPU" primaryTypographyProps={{
                  fontSize: 20,
                  fontWeight: 'medium',
                  letterSpacing: 0,
                }} />
        </ListItem>

        <ListItem sx={{py:0}}>
          <ListItemIcon/>
          <ListItemText primary="Architecture" secondary={cpu.architecture} />
        </ListItem>

        <ListItem sx={{py:0}}>
          <ListItemIcon/>
          <ListItemText primary="CPU(s)" secondary={cpu.cpus} />
        </ListItem>

        <ListItem sx={{py:0}}>
          <ListItemIcon/>
          <ListItemText primary="Frequency" secondary={cpu.freq ? cpu.freq + ' MHz' : 'No data available'} />
        </ListItem>
      </List>
    </div>
  );

  // Table with memory and disk info
  const sysCol2 = (memory,disks) => (
    <div className="table-column">
      {/* Memory Table */}
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow sx={{backgroundColor: '#F8F9FA'}}><TableCell colSpan="8" sx={{border:0, py:0}}><Typography variant="subtitle2" sx={{fontWeight:'bold'}}>Memory</Typography></TableCell></TableRow>
            <TableRow sx={{'&>*': {py:1}, backgroundColor: '#ADB5BD'}}>
              <TableCell><Typography variant="h6" sx={{fontWeight:'bold'}}>Type</Typography></TableCell>
              <TableCell><Typography variant="h6" sx={{fontWeight:'bold'}}>Total</Typography></TableCell>
              <TableCell><Typography variant="h6" sx={{fontWeight:'bold'}}>Used</Typography></TableCell>
              <TableCell><Typography variant="h6" sx={{fontWeight:'bold'}}>Free</Typography></TableCell>
              <TableCell><Typography variant="h6" sx={{fontWeight:'bold'}}>Shared</Typography></TableCell>
              <TableCell><Typography variant="h6" sx={{fontWeight:'bold'}}>Buffers</Typography></TableCell>
              <TableCell><Typography variant="h6" sx={{fontWeight:'bold'}}>Cache</Typography></TableCell>
              <TableCell><Typography variant="h6" sx={{fontWeight:'bold'}}>Available</Typography></TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {memory.map((mem, index) => (
              <TableRow key={index} sx={{'&>*': {py:1}, backgroundColor: index % 2 === 0 ? '#E9ECEF' : '#DEE2E6'}}>
                <TableCell><Typography>{mem.type}</Typography></TableCell>
                <TableCell><Typography>{mem.total}</Typography></TableCell>
                <TableCell><Typography>{mem.used}</Typography></TableCell>
                <TableCell><Typography>{mem.free}</Typography></TableCell>
                <TableCell><Typography>{mem.shared}</Typography></TableCell>
                <TableCell><Typography>{mem.buffers}</Typography></TableCell>
                <TableCell><Typography>{mem.cache}</Typography></TableCell>
                <TableCell><Typography>{mem.available}</Typography></TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {/* /////////// */}

      {/* Disks Table */}
      <TableContainer component={Paper} sx={{mt:5}}>
        <Table>
          <TableHead>
            <TableRow sx={{backgroundColor: '#F8F9FA'}}><TableCell colSpan="5" sx={{border:0, py:0}}><Typography variant="subtitle2" sx={{fontWeight:'bold'}}>Disks</Typography></TableCell></TableRow>
            <TableRow sx={{'&>*': {py:1}, backgroundColor: '#ADB5BD'}}>
              <TableCell><Typography variant="h6" sx={{fontWeight:'bold'}}>Disk</Typography></TableCell>
              <TableCell><Typography variant="h6" sx={{fontWeight:'bold'}}>Total</Typography></TableCell>
              <TableCell><Typography variant="h6" sx={{fontWeight:'bold'}}>Usage</Typography></TableCell>
              <TableCell><Typography variant="h6" sx={{fontWeight:'bold'}}>Free</Typography></TableCell>
              <TableCell><Typography variant="h6" sx={{fontWeight:'bold'}}>Mount</Typography></TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {disks.map((disk, index) => (
              <TableRow key={index} sx={{'&>*': {py:1}, backgroundColor: index % 2 === 0 ? '#E9ECEF' : '#DEE2E6'}}>
              <TableCell><Typography>{disk.disk}</Typography></TableCell>
              <TableCell><Typography>{disk.total}</Typography></TableCell>
              <TableCell><Typography>{disk.usage}</Typography></TableCell>
              <TableCell><Typography>{disk.free}</Typography></TableCell>
              <TableCell><Typography>{disk.mount}</Typography></TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {/* /////////// */}
    </div>
  );

  // Table with vhosts info
  const sysVhosts = (vhosts) => (
    <div className="table-column">
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow sx={{backgroundColor: '#F8F9FA'}}><TableCell colSpan="6" sx={{border:0, py:0}}><Typography variant="subtitle2" sx={{fontWeight:'bold'}}>Virtual Hosts</Typography></TableCell></TableRow>
            <TableRow sx={{'&>*': {py:1}, backgroundColor: '#ADB5BD'}}>
              <TableCell><Typography variant="h6" sx={{fontWeight:'bold'}}>Config. File</Typography></TableCell>
              <TableCell><Typography variant="h6" sx={{fontWeight:'bold'}}>Server Name</Typography></TableCell>
              <TableCell><Typography variant="h6" sx={{fontWeight:'bold'}}>Server Alias</Typography></TableCell>
              <TableCell><Typography variant="h6" sx={{fontWeight:'bold'}}>Disk Usage</Typography></TableCell>
              <TableCell><Typography variant="h6" sx={{fontWeight:'bold'}}>Extra Info.</Typography></TableCell>
              <TableCell><Typography variant="h6" sx={{fontWeight:'bold'}}>Comments</Typography></TableCell>
            </TableRow>
          </TableHead>

          <TableBody ref={tableRef}>
            {vhosts.map((vhost, index) => (
              <TableRow key={index} sx={{'&>*': {py:1}, backgroundColor: index % 2 === 0 ? '#E9ECEF' : '#DEE2E6'}}>
                <TableCell><Typography>{vhost.configFile}</Typography></TableCell>
                <TableCell><Typography>{vhost.serverName}</Typography></TableCell>
                <TableCell><Typography>{vhost.serverAlias}</Typography></TableCell>
                <TableCell>
                  <List sx={{listStyleType:'disc'}}>
                    {Object.entries(vhost.diskUsage).map(([key, value], index) => (
                      <ListItem key={index} sx={{py:0, display:'list-item'}}>
                        <ListItemText primary={key.toUpperCase()} secondary={value ? value : 'No Data'} />
                      </ListItem>
                    ))}
                  </List>
                </TableCell>
                <TableCell>
                  <List sx={{listStyleType:'disc'}}>
                    {Object.entries(vhost.extraInfo).map(([key, value], index) => (
                      <ListItem key={index} sx={{py:0, display:'list-item'}}>
                        <ListItemText primary={key.toUpperCase()} secondary={value ? value : 'No Data'} />
                      </ListItem>
                    ))}
                  </List>
                </TableCell>
                <TableCell><Typography>{vhost.comment}</Typography></TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );

  function handleCollapse() {
    setCollapse(!collapse);
  }

  const renderSysInfo = (data)  => (
    <TableContainer sx={{borderRadius:1, maxHeight:'90vh', overflowY: 'auto'}}>
      <Table stickyHeader>

        <TableHead>
          <TableRow sx={{backgroundColor:'#F8F9FA'}}>
            <TableCell align="center" colSpan={2} onClick={handleCollapse}>
              <TextField placeholder="Buscar VHost 🔎" variant="outlined" size="small" sx={{position:'absolute', ml:3, left:0, zIndex:1000}} value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} onClick={(e)=>{e.stopPropagation(); setCollapse(true)} } />
              
              <Button variant="text" sx={{fontWeight:'bold', color:'black', width:'60vw'}} onClick={handleCollapse}>SYSTEM INFO</Button>
              
              <Tooltip title="Descargar Excel (Experimental)"><IconButton sx={{position:'absolute', mr:9, right:0, borderRadius:1, zIndex:1000}} onClick={(e)=>{e.stopPropagation(); excelExport({data, fileName:data.hostname})}}><FileDownloadOutlinedIcon /></IconButton></Tooltip>
              <Tooltip title="Cerrar"><IconButton data-id="closeGrid" sx={{position:'absolute', mr:3, right:0, borderRadius:1, zIndex:1000}} onClick={(e)=>{e.stopPropagation(); onClose(e)}}><CloseRoundedIcon data-id="closeGrid" /></IconButton></Tooltip>
            </TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          <TableRow>
            <TableCell sx={{borderBottom:0,p:0}}>
              <Collapse in={!collapse}>
                <Table sx={{height:'1px'}}>
                <TableBody>
                  
                  <TableRow>
                    <TableCell>
                      {sysCol1(data.hostname,data.os,data.ip,data.status,data.cpu)}
                    </TableCell>
                    <TableCell>
                      {sysCol2(data.memory,data.disks)}
                    </TableCell>
                  </TableRow>

                </TableBody>
                </Table>
              </Collapse>
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell colSpan={2}>
              {sysVhosts(data.vhosts)}
            </TableCell>
          </TableRow>
        </TableBody>
        
      </Table>
    </TableContainer>
  );

  return(
    <>
      {renderSysInfo(data)}
    </>
  );
}
