import { useEffect } from 'react';
import { useLocation } from 'wouter';

export default function CheckAuth() {
  const [location, setLocation] = useLocation();
  
  useEffect(() => {
    const isAuthenticated = !!sessionStorage.getItem('authToken');
    if (!isAuthenticated) {
      setLocation('/login');
    }
  }, [location, setLocation]);

  return !!sessionStorage.getItem('authToken');
};
