import {utils, write} from 'xlsx';

export default function excelExport({ data, fileName }) {
  const workbook = utils.book_new();
  const rows = [];

  // General Info & CPU
  rows.push(['hostname', data.hostname]);
  rows.push(['os', data.os]);
  rows.push(['ip', data.ip]);
  rows.push(['status', data.status]);

  const cpuStartRow = rows.length + 2;
  rows[cpuStartRow - 1] = ['cpu'];
  const cpuKeys = Object.keys(data.cpu);
  cpuKeys.forEach(key => {
    rows.push([key, data.cpu[key]]);
  });


  // Memory Info
  const memoryStartCol = 3;
  rows[0] = rows[0] || [];
  rows[0][memoryStartCol] = 'memory';
  const memoryKeys = Object.keys(data.memory[0]);
  rows[1] = rows[1] || [];
  memoryKeys.forEach((key, index) => {
    rows[1][memoryStartCol + index] = key;
  });

  data.memory.forEach((memObject, rowIndex) => {
    rows[rowIndex + 2] = rows[rowIndex + 2] || [];
    memoryKeys.forEach((key, colIndex) => {
      rows[rowIndex + 2][memoryStartCol + colIndex] = memObject[key];
    });
  });


  // Disks Info
  const disksStartRow = rows.length + 1;
  rows[disksStartRow - 1] = [];
  rows[disksStartRow - 1][memoryStartCol] = 'disks';
  const diskKeys = Object.keys(data.disks[0]);
  rows[disksStartRow] = rows[disksStartRow] || [];
  diskKeys.forEach((key, index) => {
    rows[disksStartRow][memoryStartCol + index] = key;
  });

  data.disks.forEach((diskObject, rowIndex) => {
    rows[disksStartRow + rowIndex + 1] = rows[disksStartRow + rowIndex + 1] || [];
    diskKeys.forEach((key, colIndex) => {
      rows[disksStartRow + rowIndex + 1][memoryStartCol + colIndex] = diskObject[key];
    });
  });


  // Vhosts Info
  let allDiskUsageSubkeys = [];
  let allExtraInfoSubkeys = [];

  data.vhosts.forEach(vhost => {
    if (vhost.diskUsage) {
      allDiskUsageSubkeys = [...new Set([...allDiskUsageSubkeys, ...Object.keys(vhost.diskUsage)])];
    }
    if (vhost.extraInfo) {
      allExtraInfoSubkeys = [...new Set([...allExtraInfoSubkeys, ...Object.keys(vhost.extraInfo)])];
    }
  });

  const vhostsStartRow = rows.length + 3;
  const vhostsStartCol = 0;
  rows[vhostsStartRow - 1] = [];
  rows[vhostsStartRow] = [];

  const vhostsHeaders = [
    'configFile', 
    'serverName', 
    'serverAlias', 
    'diskUsage', 
    '', 
    'extraInfo', 
    '', 
    'comment'
  ];

  vhostsHeaders.forEach((header, index) => {
    rows[vhostsStartRow - 1][vhostsStartCol + index] = header;
  });

  allDiskUsageSubkeys.forEach((subkey, index) => {
    rows[vhostsStartRow][vhostsStartCol + 3 + index] = subkey;
  });

  allExtraInfoSubkeys.forEach((subkey, index) => {
    rows[vhostsStartRow][vhostsStartCol + 5 + index] = subkey;
  });

  data.vhosts.forEach((vhost, rowIndex) => {
    const row = [];
    row[vhostsStartCol] = vhost.configFile;
    row[vhostsStartCol + 1] = vhost.serverName;
    row[vhostsStartCol + 2] = vhost.serverAlias;

    allDiskUsageSubkeys.forEach((subkey, index) => {
      row[vhostsStartCol + 3 + index] = vhost.diskUsage ? vhost.diskUsage[subkey] || '' : '';
    });

    allExtraInfoSubkeys.forEach((subkey, index) => {
      row[vhostsStartCol + 5 + index] = vhost.extraInfo ? vhost.extraInfo[subkey] || '' : '';
    });

    row[vhostsStartCol + 7] = vhost.comment;
    rows.push(row);
  });

  // Worksheet
  const worksheet = utils.aoa_to_sheet(rows);

  // Merges
  worksheet['!merges'] = [
    { s: { r: cpuStartRow - 1, c: 0 }, e: { r: cpuStartRow - 1, c: 1 } }, //CPU
    { s: { r: 0, c: memoryStartCol }, e: { r: 0, c: memoryStartCol + memoryKeys.length - 1 } }, //MEMORY
    { s: { r: disksStartRow - 1, c: memoryStartCol }, e: { r: disksStartRow - 1, c: memoryStartCol + diskKeys.length - 1 } }, //DISKS
    //VHOSTS
    { s: { r: vhostsStartRow - 1, c: 0 }, e: { r: vhostsStartRow, c: 0 } }, 
    { s: { r: vhostsStartRow - 1, c: 1 }, e: { r: vhostsStartRow, c: 1 } }, 
    { s: { r: vhostsStartRow - 1, c: 2 }, e: { r: vhostsStartRow, c: 2 } }, 
    { s: { r: vhostsStartRow - 1, c: 3 }, e: { r: vhostsStartRow - 1, c: 3 + allDiskUsageSubkeys.length - 1 } }, 
    { s: { r: vhostsStartRow - 1, c: 5 }, e: { r: vhostsStartRow - 1, c: 5 + allExtraInfoSubkeys.length - 1 } }, 
    { s: { r: vhostsStartRow - 1, c: 7 }, e: { r: vhostsStartRow, c: 7 } }, 
  ];

  
  // Columns Width
  worksheet['!cols'] = [
    { wch: 15 },
    { wch: 25 },
    { wch: 25 },
    ...Array(memoryKeys.length).fill({ wch: 15 }),
  ];


  // Build
  utils.book_append_sheet(workbook, worksheet, fileName.slice(0,13) + ' (' + data.ip + ')');
  const excelBuffer = write(workbook, { bookType: 'xlsx', type: 'array' });
  const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

  // Download
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    // IE & Edge
    window.navigator.msSaveOrOpenBlob(blob, `${fileName}.xlsx`);
  } else {
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${fileName}.xlsx`);
    link.click();
    window.URL.revokeObjectURL(url);
  }

}
