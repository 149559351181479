import React from 'react';
import ReactDOM from 'react-dom/client';
import 'index.css';
import {ThemeProvider,createTheme} from '@mui/material/styles';

import App from 'App';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <ThemeProvider theme={createTheme({typography: {fontFamily: 'SourceCodePro, monospace'} }) }>
    <App />
  </ThemeProvider>
);
